.card {
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px !important;
    border-radius: 1.25rem !important;
}

.img {
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
}

.boxcard:hover {
    transform: scale(1.1);
}

.boxcard {
    transition: transform .4s;
    cursor: pointer;
}

.darkTheme .card-body {
    background-color: #333436 !important;
    border-radius: 0 0 15px 15px;
}

.darkTheme .card {
    background-color: #333436 !important;
    border: none !important;
    box-shadow: none !important;
}

.titlebox {
    font-size: 16px;
}

.subtitlebox {
    font-size: 14px !important;
    color: #434343 !important;
}

.premium-span-premium {
    padding: 0.55rem 1.2rem 0.55rem 1.25rem;
    position: absolute;
    color: #fff;
    top: 1rem;
    right: 0;
    /* right: calc(-0.6rem + 15px); */
    font-size: 0.9rem;
    z-index: 1;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    background-color: transparent;
    text-transform: uppercase;
    cursor: pointer;
    background-image: url(../images/bluebck.svg);
    font-size: 13px;
}

@media (min-width: 769px) {
    .img {
        height: 600px;
    }

}

@media (max-width: 769px) {
    

    .img {
        height: auto;
    }
}

@media (max-width: 575.98px) {
    .boxcard {
        width: 100% !important;
    }

    .boxcard:hover {
        transform: scale(1.05);
    }
}

