.formbg{
    background-color: #34ACE3;
    /* height: 113vh; */
}




.formcontact{
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
    padding: 25px;
    z-index: 100 !important;
    background-color: #fff;
    border-radius: 20px;
    margin: -25px 0px -40px 0px;
}

.formwett{
    border: 1px solid #ececec !important;
}

.titleform{
    font-size: 29px;
    text-align: start;
    font-weight: bold;
}

.subtitleform{
    font-size: 18px;
    color: #34ACE3 ;
    text-align: start;
    font-weight: bold;

}
.formtitle{
    font-size: 29px;
    color: white;
    font-weight: 600;
}
.formsubtitle{
    font-size: 18px;   
    color: white;
    font-weight: 400;

}

.titlecheck{
    font-size: 14px;
}

.titledesc{
    font-size: 29px;
    font-weight: 600;

}

.subtitledesc{
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
}

@media (min-width: 769px) { 
    .img{
     height: 600px;
    }
     
   }
   @media (max-width: 991px) { 
    .formcontact {
        
        margin: -25px 0px -180px 0px;
    }
  }

 @media (max-width: 769px) { 
   
    .img{
     height: auto;
    }
  }

 @media (max-width: 677px) { 
   
    .formsubtitle {
        font-size: 5px;
        color: white;
        font-weight: 400;
    }
  }

  @media (max-width: 580px) { 
   
    .formtitle {
        font-size: 10px;
        color: white;
        font-weight: 600;
    }
  }
  @media (max-width: 460px) { 
   
    .formsubtitle {
        font-size: 5px;
        color: white;
        font-weight: 400;
    }
  }


  @media (max-width: 489px) { 
    .formcontact {
        
        margin: -25px 0px -200px 0px;
    }

    .titlecheck {
      font-size: 12px;
  }
  }

  .darkTheme .formcontact{
    background-color: #282424;
  }

  .darkTheme .formbg{
    background-color: #333436;
  }


  .darkTheme .darkselect{
    background-color: #282424 !important;
    border: 1px solid #11A3E6 !important;
    color: #fff;
  }

  .darkTheme .form-select:focus {
    background-color: #282424 !important;
  }

  .darkTheme .darktitle{
    color: white;
  }

  .darkTheme .formtitle{
    color: #50B8E7 !important;
  }

  .darkTheme .titledesc{
    color: #50B8E7 !important;
  }
 
.darkTheme ::-webkit-calendar-picker-indicator {
    filter: invert(1);  
}
